var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: {
                title: "지도 미리보기",
                noMarginPadding: true,
                height: _vm.setImgHeight,
              },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("q-img", {
                      attrs: {
                        src: _vm.mapSrc,
                        fit: "scale-down",
                        contain: true,
                        ratio: 1,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "loading",
                          fn: function () {
                            return [
                              _c("q-spinner-gears", {
                                attrs: { color: "white" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "지도 목록",
                tableId: "riskHazrd01",
                columns: _vm.grid.columns,
                data: _vm.grid.data,
                filtering: false,
                columnSetting: false,
                selection: "single",
                rowKey: "sopMapId",
              },
              on: {
                getTableHeight: (height) => {
                  this.imgHeight = height
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props }) {
                    return [
                      [
                        _c("q-btn", {
                          attrs: {
                            round: "",
                            unelevated: "",
                            size: "6px",
                            color: "amber",
                            icon: "search",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.rowClick(props.row, props.pageIndex)
                            },
                          },
                        }),
                      ],
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: { label: "선택", icon: "check" },
                            on: { btnClicked: _vm.select },
                          })
                        : _vm._e(),
                      _c("c-btn", {
                        attrs: { label: "검색", icon: "search" },
                        on: { btnClicked: _vm.getList },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }